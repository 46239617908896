import request from '@/utils/request'

// 服务包管理列表
export function listPack(query) {
  return request({
    url: '/system/pack/list',
    method: 'POST',
    data: query
  })
}

// 根据服务包ID获取服务包相关信息
export function getPack(packId) {
  return request({
    url: '/system/pack',
    method: 'get',
    params: packId
  })
}

// 新增-更新服务包
export function addPack(data) {
  return request({
    url: '/system/pack',
    method: 'post',
    data: data
  })
}

// 删除服务包
export function delPack(packId) {
  return request({
    url: '/system/pack',
    method: 'delete',
    params: packId
  })
}


// 角色数据权限
export function dataScope(data) {
  return request({
    url: '/system/role/dataScope',
    method: 'put',
    data: data
  })
}

// 角色状态修改
export function changeRoleStatus(roleId, status) {
  const data = {
    roleId,
    status
  }
  return request({
    url: '/system/role/changeStatus',
    method: 'put',
    data: data
  })
}

// 导出角色
export function exportRole(query) {
  return request({
    url: '/system/role/export',
    method: 'get',
    params: query
  })
}
